@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    background-color: #6066FF;
}

.app-dark {
    .notes-row {
        background-color: black;

        .note-header {
            h4 {
                color: white;
            }
        }

        .note-content {
            form {
                input {
                    color: white;
                    background-color: rgb(15, 23, 41);

                    &::placeholder {
                        color: white;
                    }
                }

                .custom-textarea {
                    background-color: rgb(15, 23, 41);

                    &::placeholder {
                        color: white;
                    }
                }
            }
        }

        .form-btn {
            &:hover {
                color: white;
            }
        }
    }

    .not-list {
        background-color: black;

        .accordion-item {
            color: white;
            background-color: black;
        }

        .accordion-button:not(.collapsed) {
            color: white;
            background-color: black !important;
        }

        button.accordion-button.collapsed {
            color: white;
            background-color: black !important;
        }
    }
}

.form-content {
    margin: 120px 0;

    h1 {
        color: #FFF;
        font-family: Inter;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px;
        text-align: center;
    }

    form {
        width: 400px;
        height: 450px;
        border-radius: 10px;
        background: #121212;
        padding: 20px;

        input {
            width: 352px;
            height: 42px;
            flex-shrink: 0;
            border-radius: 20px;
            border: 2px solid #6066FF;
            background: transparent;
            margin-bottom: 20px;
            color: white;
            padding-left: 20px;

            &::placeholder {
                color: white;
            }
        }

        .btn-form {
            width: 176px;
            height: 42px;
            flex-shrink: 0;
            border-radius: 20px;
            border: 2px solid #9CA6FF;
            background: #8B54FF;
            color: #FFF;
            font-family: Inter Tight;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #8B54FF;
            margin-top: 20px;

            &:hover {
                background: transparent;
                border: 1px solid #8B54FF;
                transition: 0.6s;
            }
        }
    }
}

.notes-row {
    width: 1250px;
    height: auto;
    margin: 120px 0;
    background-color: white;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, .5);
    border-radius: 10px;

    .note-header {
        padding: 14px;

        h4 {
            font-family: Poppins;
            font-weight: bolder;
            text-align: center;
        }

    }

    form {
        justify-content: center;

        #form-control {
            width: 61% !important;
        }

        .form-btn {
            width: 120px;
            height: 40px;
            border: 1px solid #0d6efd;

            &:hover {
                color: black;
                background-color: transparent;
                border: 1px solid #0d6efd;
                transition: 0.6s;
            }

            &:not(:hover) {
                transition: 0.6s;
            }
        }

        .note-col {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            margin-bottom: 20px;



            .note-title {
                padding: 15px;
            }

            .note-icons {
                i {
                    padding-right: 15px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.not-list {
    width: 1250px;
    height: auto;
    background-color: white;
    margin-bottom: 50px;

    .not-header {
        padding: 14px;

        h4 {
            font-family: Poppins;
            font-weight: bolder;
            text-align: center;
        }

    }

    .not-list-content {
        padding-bottom: 50px;
    }
}

.auth-text {
    a {
        color: white;
        font-size: 15px;
        padding-top: 50px;
    }
}

.All-Notes {
    padding: 120px 0;
}

.accordion-item p {
    width: 100%;
}