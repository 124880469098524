@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');

.navbar {
    background-color: rgb(17, 17, 17);
    height: 90px;

    .navbar-brand {
        color: white;
        font-weight: bold;
    }

    .nav-link {
        color: white;
        font-family: Source Sans Pro;
    }
}
#navabr-exit-btn{
    color: white;
    text-decoration: none;
}
.checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .checkbox-label {
    background-color: #111;
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fa-moon {color: #f1c40f;}
  
  .fa-sun {color: #f39c12;}
  
  .checkbox-label .ball {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }