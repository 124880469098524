.modal-btn {
    width: 150px;
    text-align: start;
    color: black;
    background-color: transparent;
    border: 0;

    &:hover {
        color: black;
        opacity: 0.3;
        transition: 0.6s;
    }

    &:not(:hover) {
        transition: 0.6s;
    }
}

.modal-content {
    width: 950px !important;
    height: 600px;
    left: -225px;
}

.new-modal {
    color: white;
    background-color: #0d6efd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;


}

#new-notes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .first-input {
        margin-bottom: 20px;
    }

}
.custom-textarea{
    width: 750px;
    border-radius: 10px;
    padding: 20px 0px 0px 20px;
}
.new-notes-title{
    width: 750px !important;
}